<div class="header-outs" id="home" #home>
  <div class="header-w3layouts">
    <!-- Navigation -->
    <div class="header-bar" [class.active]="arrowActive">
      <nav
        class="navbar navbar-default navbar-expand-md navbar-light d-block d-md-flex"
      >
        <img
          src="./assets/images/ugaan-foundation-new-logo.png"
          class="imgLogo"
          alt="Ugaan"
          title="Ugaan"
        />
        <!-- <h1 class="d-inline-block"><span class="navbar-brand">Ugaan Foundation</span></h1> -->
        <button
          class="navbar-toggler float-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav">
            <li class="nav-item" [class.active]="tabCount == 0">
              <a class="nav-link" (click)="scrollTop(home, 0)">Home</a>
            </li>
            <li class="nav-item" [class.active]="tabCount == 1">
              <a class="nav-link" (click)="scrollTop(about, 1)">About</a>
            </li>
            <li class="nav-item" [class.active]="tabCount == 2">
              <a class="nav-link" (click)="scrollTop(services, 2)">Team</a>
            </li>
            <li class="nav-item" [class.active]="tabCount == 3">
              <a class="nav-link" (click)="scrollTop(Events, 3)">Events</a>
            </li>
            <li class="nav-item" [class.active]="tabCount == 4">
              <a class="nav-link" (click)="scrollTop(testimonials, 4)"
                >Donate</a
              >
            </li>
            <li class="nav-item" [class.active]="tabCount == 6">
              <a class="nav-link" (click)="scrollTop(gallery, 6)">Gallery</a>
            </li>
            <li class="nav-item" [class.active]="tabCount == 5">
              <a class="nav-link" (click)="scrollTop(contact, 5)">Contact</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="clearfix"></div>
  </div>
  <!-- Slideshow 4 -->
  <div class="slider">
    <div class="callbacks_container">
      <div
        id="homeSlide"
        class="carousel slide home-carousel"
        data-ride="carousel"
      >
        <!-- Indicators -->
        <!-- <ul class="carousel-indicators">
                    <li data-target="#homeSlide" data-slide-to="0" class="active"></li>
                    <li data-target="#homeSlide" data-slide-to="1"></li>
                  </ul> -->

        <!-- The slideshow -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              class="imgCarousel"
              src="./assets/images/home-slider1.png"
              alt="Help Women One at a Time"
              title="Help Women One at a Time"
            />
            <div class="container">
              <div class="slider-info">
                <h4>Help Women One at a Time</h4>
                <!-- <p>Quis autem vel eum iure reprehderit.Quis autem vel eum iure reprehderit.</p>
                           <div class="outs_more-buttn">
                              <a href="#" data-toggle="modal" data-target="#myModal">Learn More</a>
                           </div> -->
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img
              class="imgCarousel"
              src="./assets/images/home-slider2.png"
              alt="Empowering Grassroots"
              title="Empowering Grassroots"
            />
            <div class="container">
              <div class="slider-info">
                <h4>Empowering Grassroots</h4>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img
              class="imgCarousel"
              src="./assets/images/home-slider3.png"
              alt="Empowering Grassroots"
              title="Empowering Grassroots"
            />
            <div class="container">
              <div class="slider-info">
                <h4>Awareness and Prevention Programs</h4>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <img
              class="imgCarousel"
              src="./assets/images/home-slider8.JPG"
              alt="Empowering Grassroots"
              title="Empowering Grassroots"
            />
          </div>
        </div>

        <!-- Left and right controls -->
        <a
          class="carousel-control-prev callbacks_nav callbacks1_nav prev"
          href="#homeSlide"
          data-slide="prev"
        >
          <!-- <span class="carousel-control-prev-icon"></span> -->
        </a>
        <a
          class="carousel-control-next callbacks_nav callbacks1_nav next"
          href="#homeSlide"
          data-slide="next"
        >
          <!-- <span class="carousel-control-next-icon"></span> -->
        </a>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
  <!-- This is here just to demonstrate the callbacks -->
  <!-- <ul class="events">
               <li>Example 4 callback events</li>
               </ul>-->
</div>
<!-- //banner -->
<!-- modal popup learn more-->
<!-- <div class="modal about-modal fade" id="myModal1" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title">
                      <img src="./assets/images/ugaan-foundation-new-logo.png" class="imgLogos" alt="Ugaan" title="Ugaan">
                    </h4>
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

                  </div>
                  <div class="modal-body">
                     <div class="out-info">
                        <img src="./assets/images/b11.jpg" alt="" />
			<p><b>Awareness Talks</b><br>Common preventable health awareness sessions - Educating and Spreading Awareness about various common preventable illnesses among women, whose signs / symptoms are often overlooked.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div> -->
<!-- //modal -->
<!-- modal popup learn more-->
<!-- <div class="modal about-modal fade" id="myModal2" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title">
                      <img src="./assets/images/ugaan-foundation-new-logo.png" class="imgLogos" alt="Ugaan" title="Ugaan">
                    </h4>
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

                  </div>
                  <div class="modal-body">
                     <div class="out-info">
                        <img src="./assets/images/b2.jpg" alt="" />
			<p><b>Interactive Session</b><br>Interactive Session with the ASHA workers, on the risk factors, signs and symptoms of the three most prevalent illnesses afflicting women in rural India: gynaecological cancers, breast cancer, osteoporosis / vitamin D deficiency.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div> -->
<!-- //modal -->
<!-- modal popup learn more-->
<!-- <div class="modal about-modal fade" id="myModal3" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title">
                      <img src="./assets/images/ugaan-foundation-new-logo.png" class="imgLogos" alt="Ugaan" title="Ugaan">
                    </h4>
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

                  </div>
                  <div class="modal-body">
                     <div class="out-info">
                        <img src="./assets/images/b3.jpg" alt="" />
			<p><b>Empowering ASHA health workers</b><br>
			Informative Session on prevention and the early detection of the three most common ailments affecting Indian women especially in the rural areas.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div> -->
<!-- //modal -->
<!-- modal popup learn more-->
<!-- <div class="modal about-modal fade" id="myModal4" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title">
                      <img src="./assets/images/ugaan-foundation-new-logo.png" class="imgLogos" alt="Ugaan" title="Ugaan">
                    </h4>
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

                  </div>
                  <div class="modal-body">
                     <div class="out-info">
                        <img src="./assets/images/b4.jpg" alt="" />
                        <p>Raising funds for sponsoring the needs of the ASHA activists. The fund raising events was planned in the month of May 2020, but has been postponed due to Covid-19. The new dates will be announced soon. You can still donate to this noble cause via online donation in the Donate section.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div> -->
<!-- //modal -->
<!-- About Us -->
<div class="about" id="about" #about>
  <!---728x90--->

  <div class="container d-flex flex-wrap">
    <div class="col-md-5 w3l_aboutdown5">
      <!---728x90--->
      <div class="aboutright">
        <h4>What Are We</h4>
      </div>
      <div class="about-jst-right">
        <p>
          ‘Ugaan’ originates from the Hindi word ‘उगाना’ which means to nurture.
          The Ugaan Foundation is a social initiative that aims to empower and
          raise awareness in rural and remote areas of India about health,
          hygiene, wellness and commonly preventable diseases. Often in rural
          areas of India, vital information is obscure or overlooked, The Ugaan
          Foundation aims at equipping folks with all the necessary information
          required to lead a healthy life as well as removing the taboo
          associated with many illnesses.
        </p>
        <p>
          The Foundation aims at empowerment and enablement at a grassroot level
          through various government organizations (ASHA workers) and rural and
          remote institutions like schools, hostels etc. We at Ugaan aim to
          literally ‘nurture and grow’ at a grassroot level to promote health
          and wellness for all.
        </p>
      </div>
    </div>
    <div class="col-md-7 aboutside">
      <!-- <div class="aboutdown">
        <div class="jst-right"> -->
      <!-- <div class="pos-top">
            <h4>Why The Ugaan Foundation?</h4>
            <p>
              The leading three preventable illnesses afflicting the women in
              India are gynaecological+breast cancers and osteoporosis. These
              three illnesses all have notable risk factors which if kept in
              check, drastically reduce the chances of a woman contracting them.
              However, if contracted, early detection through awareness and
              routine primary health checks can lead to 100% recovery. Hence
              these illnesses are not ’death sentences.
            </p>
          </div> -->
      <!-- <div class="pos-top">
                           <h4>Vivamus elementum</h4>
                           <p>Quis autem vel eum iure reprehderit.Quis autem vel eum iure reprehderit.
                              eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis,
                           </p>
                        </div> -->
      <!-- </div>
      </div> -->
      <div class="aboutdown1">
        <img
          src="./assets/images/a2.png"
          class="img-responsive"
          alt="Ugaan Foundation aims at empowerment"
          title="Ugaan Foundation aims at empowerment"
        />
        <div class="aboutimgflex d-flex flex-wrap">
          <div class="col-md-6 col-sm-6 col-6 imgflex">
            <img
              src="./assets/images/a1.png"
              class="img-responsive"
              alt="Ugaan Foundation aims at empowerment"
              title="Ugaan Foundation aims at empowerment"
            />
          </div>
          <div class="col-md-6 col-sm-6 col-6 imgflex1">
            <img
              src="./assets/images/a3.png"
              class="img-responsive"
              alt="Ugaan Foundation aims at empowerment"
              title="Ugaan Foundation aims at empowerment"
            />
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
  <!---728x90--->
</div>
<!--//about-->
<!--services-->
<div class="services teamList" id="services" #services>
  <h3 class="title clr">Team</h3>
  <div class="banner-bottom-girds d-flex flex-wrap">
    <div class="agile_clients_content col-md-6 col-xl-3">
      <div class="a-midd-main">
        <img
          class="agile-img"
          src="./assets/images/anika.png"
          alt="Anika Uday Nayak"
          title="Anika Uday Nayak"
        />
        <h4>Anika Uday Nayak</h4>
        <p>
          <b>Founder</b><br />
          “All change starts with a distant rumble at the grassroot level”
          <br />
          Anika Uday Nayak is an aspiring medical professional and a current
          student at Dartmouth College in the US. With the hope of giving back
          to her community as well as empowering and enabling individuals at a
          fundamental grassroot level, Anika founded the ‘UGAAN Foundation’ in
          2020. An avid yoga practitioner and certified yoga teacher, she is
          also extremely interested in exploring the intersections and
          associations between holistic wellbeing, mind-body practices and human
          physiology. In her free time, Anika enjoys dancing, painting, cooking
          and reading.
        </p>
      </div>
    </div>
    <div class="agile_clients_content col-md-6 col-xl-3">
      <div class="a-midd-main">
        <img
          class="agile-img"
          src="./assets/images/ananth.jpeg"
          alt="Ananth Uday Nayak"
          title="Ananth Uday Nayak"
        />
        <h4>Ananth Uday Nayak</h4>
        <p>
          <b>Co-Founder</b><br />
          Ananth Uday Nayak is an 11th grader at National Public School, HSR
          Layout. He is passionate about sports and health and eager to explore
          its intersection while spreading the knowledge and awareness about
          health, hygiene, physical activities and general wellbeing among young
          school students , especially in rural communities where access to
          information and resources are limited. Ananth is an avid sports person
          and plays cricket (KSCA Leagues) and varsity basketball.
        </p>
      </div>
    </div>
    <div class="agile_clients_content col-md-6 col-xl-3">
      <div class="a-midd-main">
        <img
          class="agile-img"
          src="./assets/images/aruna.png"
          alt="Dr. Aruna Korlimarla"
          title="Dr. Aruna Korlimarla"
        />
        <h4>Dr. Aruna Korlimarla</h4>
        <p>
          <b>Advisor</b><br />Dr. Aruna has been an assistant professor of
          Molecular Medicine, St. John’s Research Institute, Bangalore, since
          2017. She has been working in the area of understanding molecular
          underpinnings of human diseases for the last decade and a half. Her
          work primarily involved understanding pathways in cancer but she has
          also worked with chronic disease like diabetes and obesity. She has
          extensive experience in applying molecular techniques and exploring
          genetic and epigenetic linkages to diseases.
        </p>
      </div>
    </div>
    <div class="agile_clients_content col-md-6 col-xl-3">
      <div class="a-midd-main">
        <img
          class="agile-img"
          src="./assets/images/gargi.png"
          alt="Gargi Panchangam"
          title="Gargi Panchangam"
        />
        <h4>Gargi Panchangam</h4>
        <p>
          <b>Advisor</b> <br />Gargi Panchangam is an accomplished Bharatanatyam
          artiste, trained in the Kalakshetra bAni. Gargi is an empanelled
          artiste of ICCR. She is also an accomplished a Hindustani classical
          vocalist. Gargi has a Masters degree in Molecular Genetics from San
          Jose State University, California (USA) and has worked extensively in
          Molecular research at Stanford University and the Biotech industry in
          California.  Her work spans from Breast cancer research to formulating
          molecular diagnostic kits to identify pathogens such as H1N1, MRSA etc
          Gargi runs SamarpaNa School of Bharatanatyam and Music in Bengaluru
          where she trains enthusiastic students.
        </p>
      </div>
    </div>
  </div>
  <!-- service list -->
  <!-- <div class="banner-bottom-girds d-flex flex-wrap ">
               <div class="col-md-3  col-sm-6 col-6  its-banner-grid">
                  <div class="col-md-3 left-icon-grid">
                     <span class="fa fa-book banner-icon" aria-hidden="true"></span>
                  </div>
                  <div class="col-md-9 white-shadow">
                     <h4>Education</h4>
                     <p>tur aut.maiores alias consequatur .</p>
                     <div class="clearfix"> </div>
                  </div>
               </div>
               <div class="col-md-3  col-sm-6 col-6  its-banner-grid">
                  <div class="col-md-3 left-icon-grid">
                     <span class="fa fa-tint banner-icon" aria-hidden="true"></span>
                  </div>
                  <div class="col-md-9 white-shadow">
                     <h4>Fresh Water</h4>
                     <p>
                        tur aut.maiores alias consequatur .
                     </p>
                     <div class="clearfix"> </div>
                  </div>
               </div>
               <div class="col-md-3  col-sm-6 col-6  its-banner-grid">
                  <div class="col-md-3 left-icon-grid">
                     <span class="fa fa-angellist banner-icon" aria-hidden="true"></span>
                  </div>
                  <div class="col-md-9 white-shadow">
                     <h4>Save Children</h4>
                     <p>
                        tur aut.maiores alias consequatur .
                     </p>
                     <div class="clearfix"> </div>
                  </div>
               </div>
               <div class="col-md-3  col-sm-6 col-6  its-banner-grid">
                  <div class="col-md-3 left-icon-grid">
                     <span class="fa fa-money banner-icon" aria-hidden="true"></span>
                  </div>
                  <div class="col-md-9 white-shadow">
                     <h4>Donate Money</h4>
                     <p>
                        tur aut.maiores alias consequatur .
                     </p>
                     <div class="clearfix"> </div>
                  </div>
               </div>
               <div class="clearfix"> </div>
            </div>
            <div class="banner-bottom-girds d-flex flex-wrap ">
               <div class="col-md-6 col-sm-6 col-6 char-mid-grid">
                  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque </p>
                  <div class="clearfix"> </div>
               </div>
               <div class="col-md-6 col-sm-6 col-6 char-mid-grid">
                  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque </p>
                  <div class="clearfix"> </div>
               </div>
               <div class="clearfix"> </div>
            </div>
            <div class="banner-bottom-girds d-flex flex-wrap ">
               <div class="col-md-3  col-sm-6 col-6  its-banner-grid">
                  <div class="col-md-3 left-icon-grid">
                     <span class="fa fa-check banner-icon" aria-hidden="true"></span>
                  </div>
                  <div class="col-md-9 white-shadow">
                     <h4>Health And Care</h4>
                     <p>
                        tur aut.maiores alias consequatur .
                     </p>
                     <div class="clearfix"> </div>
                  </div>
               </div>
               <div class="col-md-3 col-sm-6 col-6  its-banner-grid">
                  <div class="col-md-3 left-icon-grid">
                     <span class="fa fa-medkit banner-icon" aria-hidden="true"></span>
                  </div>
                  <div class="col-md-9 white-shadow">
                     <h4>24 Hour Care</h4>
                     <p>tur aut.maiores alias consequatur </p>
                  </div>
                  <div class="clearfix"> </div>
               </div>
               <div class="col-md-3  col-sm-6 col-6  its-banner-grid">
                  <div class="col-md-3 left-icon-grid">
                     <span class="fa fa-eye banner-icon" aria-hidden="true"></span>
                  </div>
                  <div class="col-md-9 white-shadow">
                     <h4>Scholarship</h4>
                     <p>tur aut.maiores alias consequatur .</p>
                     <div class="clearfix"> </div>
                  </div>
               </div>
               <div class="col-md-3 col-sm-6 col-6  its-banner-grid">
                  <div class="col-md-3 left-icon-grid">
                     <span class="fa fa-clock-o banner-icon" aria-hidden="true"></span>
                  </div>
                  <div class="col-md-9 white-shadow">
                     <h4>Visiting Hours</h4>
                     <p>tur aut.maiores alias consequatur
                     </p>
                  </div>
                  <div class="clearfix"> </div>
               </div>
               <div class="clearfix"> </div>
            </div> -->
</div>
<!--//services-->
<!--Event-->
<div class="news" id="Events" #Events>
  <h3 class="title">Events</h3>
  <div class="blog-two-agile">
    <div class="blog-right-top d-flex flex-wrap">
      <div class="col-xl-3 col-md-6 blog-rit-right">
        <!-- <div class="w3layouts_news_grid">
                                      <img src="./assets/images/b4.jpg" alt=" " class="img-responsive">

                                   </div> -->
        <div class="outs_news_grid text-center clr-2">
          <h6>Funding for ASHA</h6>
          <ul>
            <li>
              <span class="fa fa-map-marker" aria-hidden="true"></span
              >Bengaluru, Karnataka
            </li>
            <li>
              <span class="fa fa-calendar" aria-hidden="true"></span>On Going
            </li>
          </ul>
          <p>
            To empower the ASHA workers and enable them to monitor the health
            conditions of elderly effectively in the COVID-19 pandemic, we have
            started a funding campaign ‘Funding For Asha’ in order to procure
            the equipment necessary for them to fulfil their roles efficiently.
          </p>

          <!-- <a href="#" class="blog-more" data-toggle="modal" data-target="#myModal4">Read More</a> -->
        </div>
      </div>
      <div class="col-xl-3 col-md-6 blog-rit-right">
        <!-- <div class="w3layouts_news_grid">
                          <img src="./assets/images/b11.jpg" alt=" " class="img-responsive">

                       </div> -->
        <div class="outs_news_grid text-center clr-1">
          <h6>Awareness Talks</h6>
          <ul>
            <li>
              <span class="fa fa-map-marker" aria-hidden="true"></span
              >Hirebettu, Udupi
            </li>
            <li>
              <span class="fa fa-calendar" aria-hidden="true"></span>17th August
              2020
            </li>
          </ul>
          <p>
            Common preventable health awareness sessions - Educating and
            Spreading Awareness about various common preventable illnesses among
            women, whose signs / symptoms are often overlooked.
          </p>

          <a class="blog-more" data-toggle="modal" data-target="#ashaWorks"
            >View Report</a
          >
        </div>
      </div>
      <div class="col-xl-3 col-md-6 blog-rit-right">
        <!-- <div class="w3layouts_news_grid">
                        <img src="./assets/images/b2.jpg" alt=" " class="img-responsive">

                     </div> -->
        <div class="outs_news_grid text-center clr-2">
          <h6>Interactions</h6>
          <ul>
            <li>
              <span class="fa fa-map-marker" aria-hidden="true"></span>Hiriadka,
              Karnataka
            </li>
            <li>
              <span class="fa fa-calendar" aria-hidden="true"></span>June, 2020
            </li>
          </ul>
          <p>
            Interactive Session with the ASHA workers, on the risk factors,
            signs and symptoms of the three most prevalent illnesses afflicting
            women in rural India: gynaecological cancers, breast cancer,
            osteoporosis / vitamin D deficiency.
          </p>

          <!-- <a href="#" class="blog-more" data-toggle="modal" data-target="#myModal2">Read More</a> -->
        </div>
      </div>
      <div class="col-xl-3 col-md-6 blog-rit-right">
        <!-- <div class="w3layouts_news_grid">
                        <img src="./assets/images/b3.jpg" alt=" " class="img-responsive">

                     </div> -->
        <div class="outs_news_grid text-center clr-1">
          <h6>Empowering ASHA health workers</h6>
          <ul>
            <li>
              <span class="fa fa-map-marker" aria-hidden="true"></span>Udupi,
              Karnataka
            </li>
            <li>
              <span class="fa fa-calendar" aria-hidden="true"></span>To be
              announced
            </li>
          </ul>
          <p>
            Informative Session on prevention and the early detection of the
            three most common ailments affecting Indian women especially in the
            rural areas.
          </p>

          <!-- <a href="#" class="blog-more" data-toggle="modal" data-target="#myModal3">Read More</a> -->
        </div>
      </div>

      <div class="clearfix"></div>
    </div>
    <!-- <div class="blog-right d-flex flex-wrap">
                  <div class="col-md-3 col-6 blog-rit-right ">
                     <div class="w3layouts_news_grid">
                        <img src="./assets/images/b3.jpg" alt=" " class="img-responsive">

                     </div>
                     <div class="outs_news_grid text-center clr-1">
                        <h6>Lorem ipsum</h6>
                        <ul>
                           <li><span class="fa fa-calendar" aria-hidden="true"></span>28 March 2017</li>
                           <li><span class="fa fa-clock-o" aria-hidden="true"></span>9:00 AM</li>
                        </ul>
                        <a href="#" class="blog-more" data-toggle="modal" data-target="#myModal">Read More</a>
                     </div>
                  </div>
                  <div class="col-md-3 col-6 blog-rit-right ">
                     <div class="w3layouts_news_grid">
                        <img src="./assets/images/b11.jpg" alt=" " class="img-responsive">

                     </div>
                     <div class="outs_news_grid text-center clr-2">
                        <h6>Lorem ipsum</h6>
                        <ul>
                           <li><span class="fa fa-calendar" aria-hidden="true"></span>28 March 2017</li>
                           <li><span class="fa fa-clock-o" aria-hidden="true"></span>9:00 AM</li>
                        </ul>
                        <a href="#" class="blog-more" data-toggle="modal" data-target="#myModal">Read More</a>
                     </div>
                  </div>
                  <div class="col-md-3 col-6 blog-rit-right ">
                     <div class="w3layouts_news_grid">
                        <img src="./assets/images/b4.jpg" alt=" " class="img-responsive">

                     </div>
                     <div class="outs_news_grid text-center clr-1">
                        <h6>Lorem ipsum</h6>
                        <ul>
                           <li><span class="fa fa-calendar" aria-hidden="true"></span>28 March 2017</li>
                           <li><span class="fa fa-clock-o" aria-hidden="true"></span>9:00 AM</li>
                        </ul>
                        <a href="#" class="blog-more" data-toggle="modal" data-target="#myModal">Read More</a>
                     </div>
                  </div>
                  <div class="col-md-3 col-6 blog-rit-right ">
                     <div class="w3layouts_news_grid">
                        <img src="./assets/images/b2.jpg" alt=" " class="img-responsive">

                     </div>
                     <div class="outs_news_grid text-center clr-2">
                        <h6>Lorem ipsum</h6>
                        <ul>
                           <li><span class="fa fa-calendar" aria-hidden="true"></span>28 March 2017</li>
                           <li><span class="fa fa-clock-o" aria-hidden="true"></span>9:00 AM</li>
                        </ul>
                        <a href="#" class="blog-more" data-toggle="modal" data-target="#myModal">Read More</a>
                     </div>
                  </div>
                  <div class="clearfix"> </div>
               </div> -->
    <div class="clearfix"></div>
  </div>
</div>
<!--//Event-->
<!--team-->
<!--
         <div class="team" id="team" #team>
            <div class="container d-flex flex-wrap">
               <h3 class="title clr col-12 p-0">Our Volunteers</h3>
               <div class="at-column col-md-3 col-sm-6 col-6">
                  <div class="at-user">
                     <div class="at-user__avatar"><img src="./assets/images/t1.jpg" alt="img"></div>
                     <h4>Aaron Rossi</h4>
                     <div class="at-user__title">
                        <h6>Volunteer</h6>
                     </div>
                     <ul class="at-social">
                        <li class="at-social__item"><a href="">
                           <span class="fa fa-facebook" aria-hidden="true"></span></a>
                        </li>
                        <li class="at-social__item"><a href="">
                           <span class="fa fa-twitter" aria-hidden="true"></span></a>
                        </li>
                        <li class="at-social__item"><a href="">
                           <span class="fa fa-dribbble" aria-hidden="true"></span></a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="at-column col-md-3 col-sm-6 col-6">
                  <div class="at-user">
                     <div class="at-user__avatar"><img src="./assets/images/t2.jpg" alt="img"></div>
                     <h4>Sara Gomez</h4>
                     <div class="at-user__title">
                        <h6>Baby Sitter</h6>
                     </div>
                     <ul class="at-social">
                        <li class="at-social__item"><a href="">
                           <span class="fa fa-facebook" aria-hidden="true"></span></a>
                        </li>
                        <li class="at-social__item"><a href="">
                           <span class="fa fa-twitter" aria-hidden="true"></span></a>
                        </li>
                        <li class="at-social__item"><a href="">
                           <span class="fa fa-dribbble" aria-hidden="true"></span></a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="at-column col-md-3 col-sm-6 col-6">
                  <div class="at-user">
                     <div class="at-user__avatar"><img src="./assets/images/t3.jpg" alt="img"></div>
                     <h4>Rose Joe</h4>
                     <div class="at-user__title">
                        <h6>Care Taker</h6>
                     </div>
                     <ul class="at-social">
                        <li class="at-social__item"><a href="">
                           <span class="fa fa-facebook" aria-hidden="true"></span></a>
                        </li>
                        <li class="at-social__item"><a href="">
                           <span class="fa fa-twitter" aria-hidden="true"></span></a>
                        </li>
                        <li class="at-social__item"><a href="">
                           <span class="fa fa-dribbble" aria-hidden="true"></span></a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="at-column col-md-3 col-sm-6 col-6">
                  <div class="at-user">
                     <div class="at-user__avatar">
                        <img src="./assets/images/t4.jpg" alt="img">
                     </div>
                     <h4>Mitch Petty</h4>
                     <div class="at-user__title">
                        <h6>Volunteer</h6>
                     </div>
                     <ul class="at-social">
                        <li class="at-social__item"><a href="">
                           <span class="fa fa-facebook" aria-hidden="true"></span></a>
                        </li>
                        <li class="at-social__item"><a href="">
                           <span class="fa fa-twitter" aria-hidden="true"></span></a>
                        </li>
                        <li class="at-social__item"><a href="">
                           <span class="fa fa-dribbble" aria-hidden="true"></span></a>
                        </li>
                     </ul>
                  </div>
               </div>
	    </div>
   </div> -->
<!--gallary-->
<div class="testimonials" id="gallary" #gallery>
  <h3 class="title tit-clr">Gallery</h3>

  <div class="address-below gallery">
    <img
      class="pdfThumnail col-6 col-md-3"
      src="./assets/images/group4.png"
      alt="pdf"
      data-toggle="modal"
      data-target="#pdfWorks"
    />
    <img
      class="pdfThumnail col-6 col-md-3"
      src="./assets/images/home-slider4.JPG"
      alt="pdf"
      data-toggle="modal"
      data-target="#pdfWorks"
    />

    <img
      class="pdfThumnail col-6 col-md-3"
      src="./assets/images/gallery1.jpeg"
      alt="pdf"
      data-toggle="modal"
      data-target="#pdfWorks"
    />
    <img
      class="pdfThumnail col-6 col-md-3"
      src="./assets/images/gallery2.jpeg"
      alt="pdf"
      data-toggle="modal"
      data-target="#pdfWorks"
    />
  </div>
</div>
<!--testimonials-->
<div class="testimonials" id="testimonials" #testimonials>
  <h3 class="title tit-clr">Donate</h3>
  <!--  payment -->
  <div class="address-below">
    <div class="contact-icons text-center d-flex flex-wrap p-0">
      <!-- <div class="col-md-4 col-sm-4 col-xs-4 footer_grid_left">
                     <div class="address-gried">
			     <p><a href="https://rzp.io/l/HIovhZn"><img src="../assets/images/razorpay-donate.png" width="200" height="44"></a></p>
                     </div>
                     <div class="clearfix"> </div>
                  </div> -->
      <div class="col-12 footer_grid_left">
        <div class="address-gried">
          <a class="my-3 d-inline-block" href="https://rzp.io/l/HIovhZn"
            ><img
              src="../assets/images/razorpay-donate.png"
              width="200"
              height="44"
              alt="Donate"
              title="Donate"
          /></a>
          <!-- <a class="px-5 my-3 d-inline-block" href="https://rzp.io/l/HIovhZn"
            ><img
              src="../assets/images/paypal-donate.png"
              width="160"
              height="44"
              alt="Donate"
              title="Donate"
          /></a>
          <a href="https://rzp.io/l/HIovhZn"
            ><img
              src="../assets/images/paytm-donate.png"
              width="200"
              height="44"
              alt="Donate"
              title="Donate"
          /></a> -->
        </div>
        <div class="clearfix"></div>
      </div>
      <!-- <div class="col-md-4 col-sm-4 col-xs-4 footer_grid_left">
                     <div class="address-gried">
		           <p><a href="https://rzp.io/l/HIovhZn"><img src="../assets/images/paytm-donate.png" width="200" height="44"></a></p>
                     </div>
                  <div class="clearfix"> </div>
               </div> -->
    </div>
  </div>
</div>
<!-- //testimonials-->
<!-- Contact-form -->
<div class="contact" id="contact" #contact>
  <div class="container">
    <h3 class="title clr">Contact us</h3>
    <div class="w3layouts_mail_grid_right d-flex flex-wrap">
      <!-- <form action="#" method="post"> -->
      <!--
                     <div class="col-md-6 col-6 wthree_contact_left_grid">
                        <input type="text" name="Name" placeholder="Name" required="">
                     </div>
                     <div class="col-md-6 col-6 wthree_contact_left_grid">
                        <input type="email" name="Email" placeholder="Email" required="">
                     </div>
                     <div class="col-md-6 col-6 wthree_contact_left_grid">
                        <input type="text" name="Telephone" placeholder="Telephone" required="">
                     </div>
                     <div class="col-md-6 col-6 wthree_contact_left_grid">
                        <input type="text" name="Subject" placeholder="Subject" required="">
                     </div>
                     <div class="clearfix"> </div>
                     <textarea name="Message" placeholder="Message..." required=""></textarea>
                     <input type="submit" value="Submit">
		     <input type="reset" value="Clear"> -->
      <!-- </form> -->
    </div>
  </div>
  <div class="address-below">
    <div class="contact-icons text-center d-flex flex-wrap">
      <div class="col-md-4 col-sm-4 col-xs-4 footer_grid_left">
        <div class="icon_grid_left">
          <span class="fa fa-map-marker" aria-hidden="true"></span>
        </div>
        <div class="address-gried">
          <p>Bengaluru<span>India</span></p>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4 footer_grid_left">
        <div class="icon_grid_left">
          <span class="fa fa-volume-control-phone" aria-hidden="true"></span>
        </div>
        <div class="address-gried">
          <p>+91-9606210535</p>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4 footer_grid_left">
        <div class="icon_grid_left">
          <span class="fa fa-envelope" aria-hidden="true"></span>
        </div>
        <div class="address-gried">
          <p>
            <a href="mailto:ugaanfoundation@gmail.com"
              >ugaanfoundation@gmail.com</a
            >
            <!-- <span><a href="mailto:ugaanfoundation@gmail.com">info@example2.com</a></span> -->
          </p>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
<!--map-->
<div class="map">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248849.88654003857!2d77.49085274762703!3d12.95395998717135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1589960188176!5m2!1sen!2sin"
    width="600"
    height="450"
    frameborder="0"
    style="border: 0"
    allowfullscreen=""
    aria-hidden="false"
    tabindex="0"
  ></iframe>
</div>
<!-- //map-->
<!-- //Contact-form -->
<!--footer-->
<div class="footer">
  <div class="container d-flex flex-wrap">
    <div class="col-md-5 left-side">
      <h2>
        <img
          src="../assets/images/ugaan-bottom-logo-new.png"
          class="bottomLogo"
          alt="Empowering Grassroots"
          title="Empowering Grassroots"
        />
        <!-- <a href="index.html">Ugaan Foundation</a> -->
      </h2>
      <span class="cap">Empowering Grassroots</span>
      <!-- <div class="icons">
                     <ul>
                        <li><a href="#"><span class="fa fa-facebook"></span></a></li>
                        <li><a href="#"><span class="fa fa-twitter"></span></a></li>
                        <li><a href="#"><span class="fa fa-rss"></span></a></li>
                        <li><a href="#"><span class="fa fa-vk"></span></a></li>
                     </ul>
                  </div> -->
    </div>
    <div class="col-md-7 right-side">
      <!-- <nav>
                     <ul class="nav-buttom">
                      <li><a class="page-scroll scroll" (click)="scrollTop(home, 0)">Home</a></li>
                      <li><a class="page-scroll scroll" (click)="scrollTop(about, 1)">About</a></li>
                      <li><a class="page-scroll scroll" (click)="scrollTop(services, 2)">Services</a></li>
                      <li><a class="page-scroll scroll"  (click)="scrollTop(Events, 3)">Events</a></li>
                      <li><a class="page-scroll scroll" (click)="scrollTop(testimonials, 4)">Donate</a></li>
                      <li><a class="page-scroll scroll" (click)="scrollTop(contact, 5)">Contact</a></li>
                     </ul>
                  </nav> -->
      <div class="bottom-copy-wrt">
        <p>&copy;2023 Ugaan Foundation. All Rights Reserved</p>
      </div>
    </div>
  </div>
</div>
<!--footer-->
<div id="toTop" *ngIf="arrowActive" (click)="scrollTop(home, 0)"></div>
<!-- modal popup learn more-->

<div class="modal fade" id="pdfWorks" tabindex="-1" role="dialog" st>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Gallery</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          id="pdfSlide"
          class="carousel slide home-carousel"
          data-ride="carousel"
        >
          <!-- The slideshow -->
          <div class="carousel-inner">
            <div class="carousel-item active">
              <iframe
                class="imgCarousel"
                src="./assets/images/UgaanPamphletFinal.pdf"
              ></iframe>
              <!-- <img
                class="imgCarousel"
                src="./assets/images/home-slider5.JPG"
                alt="Empowering Grassroots"
                title="Empowering Grassroots"
              /> -->
            </div>

            <div class="carousel-item">
              <img
                class="imgCarousel"
                src="./assets/images/group0.png"
                alt="Empowering Grassroots"
                title="Empowering Grassroots"
              />
            </div>
            <div class="carousel-item">
              <img
                class="imgCarousel"
                src="./assets/images/group2.png"
                alt="Empowering Grassroots"
                title="Empowering Grassroots"
              />
            </div>
            <div class="carousel-item">
              <img
                class="imgCarousel"
                src="./assets/images/group6.png"
                alt="Empowering Grassroots"
                title="Empowering Grassroots"
              />
            </div>
            <div class="carousel-item">
              <img
                class="imgCarousel"
                src="./assets/images/group11.png"
                alt="Empowering Grassroots"
                title="Empowering Grassroots"
              />
            </div>
            <div class="carousel-item">
              <img
                class="imgCarousel"
                src="./assets/images/group3.png"
                alt="Empowering Grassroots"
                title="Empowering Grassroots"
              />
            </div>

            <div class="carousel-item">
              <img
                class="imgCarousel"
                src="./assets/images/home-slider5.JPG"
                alt="Empowering Grassroots"
                title="Empowering Grassroots"
              />
            </div>
            <div class="carousel-item">
              <img
                class="imgCarousel"
                src="./assets/images/gallery1.jpeg"
                alt="Empowering Grassroots"
                title="Empowering Grassroots"
              />
            </div>
            <div class="carousel-item">
              <img
                class="imgCarousel"
                src="./assets/images/gallery2.jpeg"
                alt="Empowering Grassroots"
                title="Empowering Grassroots"
              />
            </div>

            <div class="carousel-item">
              <img
                class="imgCarousel"
                src="./assets/images/gallery3.jpeg"
                alt="Empowering Grassroots"
                title="Empowering Grassroots"
              />
            </div>
          </div>

          <!-- Left and right controls -->
          <a
            class="carousel-control-prev callbacks_nav callbacks1_nav prev"
            href="#pdfSlide"
            data-slide="prev"
          >
            <!-- <span class="carousel-control-prev-icon"></span> -->
          </a>
          <a
            class="carousel-control-next callbacks_nav callbacks1_nav next"
            href="#pdfSlide"
            data-slide="next"
          >
            <!-- <span class="carousel-control-next-icon"></span> -->
          </a>
        </div>
        <!-- <iframe src="./assets/images/UgaanPamphletFinal.pdf" style="width:100%;height: 500px;"></iframe> -->
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ashaWorks" tabindex="-1" role="dialog" st>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          Interactive session at Hirebettu Primary Healthcare Center
          <!-- <img
            src="./assets/images/ugaan-foundation-new-logo.png"
            class="imgLogos"
            alt="Ugaan"
            title="Ugaan"
          /> -->
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="out-info">
          <div class="imgList">
            <img src="./assets/images/group0.png" alt="ugaan foundation" />
            <img
              src="./assets/images/home-slider8.JPG"
              alt="ugaan foundation"
            />
          </div>
          <p>
            An interactive session with the ASHA (Accredited Social Health
            Activists) workers was held on 17th August 2020 by the Ugaan
            Foundation at the Hirebettu Primary Healthcare Center, Hirebettu
            Village, Udupi. The session was attended by 35 ASHA workers
            belonging to the Hirebettu division which is further divided into
            Athrady, Hirebettu, Herga, and Badagubettu sub-divisions.
          </p>
          <div class="imgList">
            <img
              src="./assets/images/home-slider4.JPG"
              alt="ugaan foundation"
            />
            <img
              src="./assets/images/home-slider5.JPG"
              alt="ugaan foundation"
            />
          </div>

          <p>
            The session was inaugurated by the doctor at the PHC, Dr. Narsimha
            Nayak, followed by which an interactive session was held where the
            ASHA worker were informed of the early signs and symptoms of the
            most prevalent and common ailments affecting women in rural areas,
            including breast cancer, cervical cancer, oral cancer as well as
            osteoporosis. In order to help them keep these symptoms in mind,
            material was distributed to the workers, to help them spread the
            word among rural women. It was an absolute success of an event, and
            the Ugaan Foundation looks forward to interacting with more ASHA
            workers and furthering its cause. Preceding this session, as part of
            the COVID pandemic relief scheme, a fundraiser had been conducted in
            order to procure the equipment necessary to enable the ASHA workers
            to fulfil their roles efficiently. In addition to the session, with
            the funds raised through the fundraiser, all the equipment procured
            (which included, Sphygmomanometers, Glucometers, and Pulse
            Oximeters) were distributed among the workers and they were trained
            on the usage.
          </p>
          <div class="imgList">
            <img
              src="./assets/images/group4.png"
              alt="ugaan foundation"
              style="object-position: 80%"
            />
            <img
              src="./assets/images/group6.png"
              alt="ugaan foundation"
              style="object-position: 15%"
            />
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <a href="https://rzp.io/l/HIovhZn">
          <button type="button" class="btn btn-primary">Donate Now</button>
        </a>
      </div> -->
    </div>
  </div>
</div>

<button
  type="button"
  class="btn btn-primary"
  data-toggle="modal"
  data-target="#donateModel"
  id="openModalButton"
  [hidden]="true"
>
  Open modal
</button>

<!-- modal popup learn more-->
<div class="modal fade" id="donateModel" tabindex="-1" role="dialog" st>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          Funding for ASHA
          <!-- <img
            src="./assets/images/ugaan-foundation-new-logo.png"
            class="imgLogos"
            alt="Ugaan"
            title="Ugaan"
          /> -->
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="out-info">
          <p>
            With the ongoing COVID-19 pandemic, access to preventive healthcare
            and health monitoring services has become limited, causing a
            challenge in monitoring the chronic ailments as well as the
            well-being of the elderly especially in rural areas. Throughout the
            pandemic, the ASHA healthcare workers have been in the frontline,
            playing multiple roles- of health care facilitators, health
            activists and service providers-putting their lives at risk. To
            empower the ASHA workers and enable them to monitor the health
            conditions of elderly effectively, we have started a funding
            campaign ‘Funding For ASHA’ in order to procure the equipment
            necessary for them to fulfil their roles efficiently.
          </p>
          <p>
            In order to procure all the required equipment, we are requesting
            your support.
          </p>
          <p>
            We would greatly appreciate any donation you make towards this
            campaign.
          </p>
          <p>
            All the funds collected will be used to procure Blood Pressure
            Monitoring Machines (Sphygmomanometers), Blood Glucose Monitors, as
            well as necessities like, face shields, disposable gloves and masks.
          </p>
          <p>
            We, at the Ugaan Foundation, would like to thank you in advance for
            your support.
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <a href="https://rzp.io/l/HIovhZn">
          <button type="button" class="btn btn-primary">Donate Now</button>
        </a>
      </div>
    </div>
  </div>
</div>
