import { Component, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  tabCount = 0;
  arrowActive = false;
  constructor(@Inject(DOCUMENT) private document: Document) { }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      this.arrowActive = true;
    }else{
      this.arrowActive = false;
    }
  }

  ngOnInit(){
    // document.getElementById("openModalButton").click();
  }

  scrollTop(el: HTMLElement, val) {
    this.tabCount = Number(val);
    // el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    const elementPosition = this.document.getElementById(el.id).offsetTop;
    window.scrollTo({
      top: elementPosition - 75, //add your necessary value
      behavior: "smooth"  //Smooth transition to roll
    });
  }
}
